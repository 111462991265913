@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&family=Quicksand:wght@300;700&display=swap');
.App {
    text-align: center;
    height: 100vh;
}

@layer base {
    button {
        @apply border border-black rounded-xl px-5 py-1
    }
}

body {
    font-family: 'Maven Pro', sans-serif;
}

body::-webkit-scrollbar {
    display: none;
}

#hideScroll::-webkit-scrollbar {
    display: none;
}

.scroll-container {
    max-height: 180px;
    overflow-y: auto;
    /* Customize scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: #4790E4 transparent;
}

.scroll-container::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: #2F2E2E;
    border-radius: 5px;
}

.noteScroll {
    max-height: 737px;
    overflow-y: auto;
    /* Customize scrollbar styles */
    scrollbar-width: thin;
    scrollbar-color: #4790E4 transparent;
}

.noteScroll::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.noteScroll::-webkit-scrollbar-thumb {
    background-color: #2F2E2E;
    border-radius: 5px;
}

.app-content {
    margin: 1rem auto;
    width: 80%;
    padding: 5%;
    background-color: #f4f5f9;
}


/*////////////////////////////////////// Css avaprofile /////////////////////////////////// */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

:root {
    --square-size: 150px;
    --shadow: 0 0 10px 0 rgba(255, 255, 255, .35);
    --font-color: rgb(250, 250, 250);
}

.profile-pic {
    color: transparent;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all .3s ease;
}

.profile-pic input {
    display: none;
}

.profile-pic img {
    position: absolute;
    object-fit: cover;
    width: var(--square-size);
    height: var(--square-size);
    box-shadow: var(--shadow);
    border-radius: 8px;
    z-index: 0;
}

.profile-pic .-label {
    cursor: pointer;
    height: var(--square-size);
    width: var(--square-size);
}

.profile-pic:hover .-label {
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    z-index: 10000;
    color: var(--font-color);
    transition: background-color .2s ease-in-out;
    /*border-radius: var(--radius);*/
    margin-bottom: 0;
}

.profile-pic span {
    display: inline-flex;
    padding: .2em;
    height: 2em;
}

.sticky-bottom-row {
    position: sticky;
    bottom: 0;
}

.sticky-summary .ant-table-summary {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;
}

/*Vcard color mode*/
.light-mode {
    background-color: white;
    color: #2B3F6C;
}

/* Dark Mode */
.dark-mode {
    background-color: #3E3A3A;
    color: #D9DBDF;
}

/*Tool tips*/
/* Add this CSS code to your stylesheet */
/* Add this CSS code to your stylesheet */

/* Add this CSS code to your stylesheet */

/* Add this CSS code to your stylesheet */

.selectTime {
    background: #F5F5F5;
}

.ant-table-placeholder {
    display: none;
}

.border-top {
    border-top: 1px solid #D5D5D5
}

.border-bottom {
    border-bottom: 1px solid #D5D5D5
}

.border-right {
    border-right: 1px solid #D5D5D5
}
.border-left {
    border-left: 1px solid #D5D5D5
}

.attendance-table{
    border-radius: 10px;
}

.scroll {
    overflow: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

table.rounded::before, table.rounded::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #ccc;
    border-radius: 10px;
}

.filter-black {
    filter: brightness(0) saturate(100%);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #F0C528;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.learning > input {
    text-align: center;
}

.ant-table-column-has-sorters .ant-table-column-sorter {
    display: none;
}

.ant-table-column-has-sorters:hover .ant-table-column-sorter{
    display: block;
}

.ant-table-cell {
    padding: 8px !important;
}

.study-dashboard .ant-table-summary{
    z-index: 100 !important;
}
