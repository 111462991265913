#loginText {
    position: absolute;
    top: 14%;
    left: 17%;
}

#dotSlide {
    /*position: absolute;*/
    /*top: 14%;*/
    /*left: 17%;*/
}

.login-page {
    position: absolute;
    bottom: 33%;
    right: 12%;
    width: 421px;
    padding: 8% 0 0;
    margin: auto;
    border-radius: 16px;
}

.form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 421px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #FFFFFF;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #2F2E2E;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.9);
}

.form button:hover, .form button:active, .form button:focus {
    background: #F0C528;
}

.form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
}

.form .message a {
    color: #4CAF50;
    text-decoration: none;
}

.form .register-form {
    display: none;
}

.container {
    position: relative;
    z-index: 1;
    max-width: 300px;
    margin: 0 auto;
}

.container:before, .container:after {
    content: "";
    display: block;
    clear: both;
}

.container .info {
    margin: 50px auto;
    text-align: center;
}

.container .info h1 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 36px;
    font-weight: 300;
    color: #1a1a1a;
}

.container .info span {
    color: #4d4d4d;
    font-size: 12px;
}

.container .info span a {
    color: #000000;
    text-decoration: none;
}

.container .info span .fa {
    color: #EF3B3A;
}

