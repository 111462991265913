.MuiDialog-container > .MuiPaper-root {
    height: auto;
    border-radius: 16px;
    border-width: 0
}
.MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root {
    padding: 0;
}

.react-tabs__tab-list {
    margin: 0 !important;
    border-bottom: 0 !important;
}

.react-tabs__tab {
    color: #D5D5D5 !important;
}

.react-tabs__tab--selected {
    color: black !important;
    font-weight: bold;
    border-width: 1px 1px 0px 1px !important;
    border-color: #e5e7eb !important;
}

.react-tabs__tab-panel--selected {
    height: 100% !important;
}

.react-tabs__tab-list {
    display: flex !important;
}

.react-tabs__tab:focus:after {
    left: 0 !important;
}

.grid .item__body p {
    text-align: unset;
}
.original-text {
    font-family: BaiJamjuree;
}
.text-gray-light {
    --tw-text-opacity: 1;
    color: rgb(218 220 224/var(--tw-text-opacity));
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.react-tabs {
    height: 100% !important;
}
