button {
    transition: background-color 0.3s, box-shadow 0.3s;
}

.custom-button:hover {
    background-color: #9e9696;
}

.custom-button:active {
    background-color: #9e9696;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.font-nunito {
    font-family: 'Nunito', sans-serif;
}

.slider-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-container {
    text-align: center;
}

.slider-item .image-container,
.slider-item .image-container div {
    height: 100%;
    width: auto;
}

.carousel-dots {
    margin-bottom: 7px !important;
    margin-top: unset !important;
}

.slick-dots li button:before {
    color: #FA9A85;
}

.slider-item .image-container img {
    height: 152px;
    width: 152px;
}

.carousel-dots li button:before {
    color: #FA9A85 !important;
}

.custom-slider .carousel-dots li {
    margin: 0 1px;
}

input, textarea {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    gap: 4px;
    border: 1px solid #D5D5D5;
    background-color: #F6F6F6;
}

.required:after {
    content:" *";
    color: red;
}

button:focus {
    border-color: #D5D5D5;
}
